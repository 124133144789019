import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CTA from "../../components/cta"
import ServiceAreas from "../../components/serviceAreas"
import Education from "../../components/education"
import SubServicesAlt from "../../components/subServicesAlt"
import Testimonials from "../../components/testimonials"
import HeroSection from "../../components/heroSection"

function SewerLineRepair() {
  const data = useStaticQuery(graphql`
    query sewerLineRepair {
      sewerRepair: file(relativePath: { eq: "sewer-repair.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Sewer Line Repair & Installation"
        description={`Whether your sewer line needs to be repaired, replaced, or installed, we're able to help. Our team has been repairing sewer lines for over 30 years.`}
      />
      <HeroSection
        h1={`Sewer Services`}
        h2={`Sewer Line Repair, Replacement, and Installation`}
        h3={`A broken sewer system can be a headache, but our experienced team can complete your repair with as little disruption as possible. As a family-owned plumbing company, we make the job convenient, fast, and affordable.`}
        p1={`Our experts are able to minimize the digging and clutter as much as possible with a direct and efficient process. We locate and excavate the faulty sewer line, remove the damaged sections, and replace them with new and secure line. After our work is complete, we rebury the line and clean up the work area to leave things looking the way we found them.`}
        heroImg={data.sewerRepair.childImageSharp.fluid}
        heroImgAltTag={`Sewer Line Repair and Installation`}
      />
      <section>
        <div className="pb-16 px-2 md:px-0">
          <h2 className="font-bold leading-8 text-2xl mb-4">
            About Klein Plumbing Services
          </h2>
          <div className="flex flex-col-reverse lg:flex-row">
            <div className="md:w-full lg:mr-8">
              <div className="md:flex">
                <p className="text-gray-600 leading-normal text-lg mb-4"></p>
              </div>
              <div>
                <p className="text-gray-600 text-lg mb-4">
                  With over 30 years of experience, owner and master plumber Sid
                  Bruton founded Klein Plumbing Services in 2006. The goal from
                  the company’s inception was to provide clear, transparent, and
                  honest plumbing solutions and treat clients like family. Our
                  team is licensed, insured, and knowledgable.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Education
        mainTitle={`Signs Your Sewer Lines Need Repair`}
        titleOne={`It's Slow Draining`}
        descriptionOne={`If your home's drains aren't functioning correctly even after attempts to clear the line, it might be a symptom that your sewer line has a blockage. Things like tree root intrusion, channeling, or cracks in your sewer line could be the cause.`}
        titleTwo={`It's Leaking`}
        descriptionTwo={`A pool of septic waste in your yard is a clear sign that your sewer line needs to be repaired or even replaced. Besides being a health concern, a sewer leak can cause significant damage to your home and should be fixed immediately.`}
        titleThree={`Foundation Issues`}
        descriptionThree={`Extreme symptoms of sewer pipe deterioration include issues with your home's foundation. If the main line running underneath the foundation slab is leaking, it can cause foundation cracks, voids, or even sinkholes.`}
      />
      <SubServicesAlt
        titleOne={`Sewer Line Repair`}
        descriptionOne={`Our team's goal is to repair your sewer line while being as minimally invasive as possible. We pay careful attention to your home and yard as we work to repair your leaking or clogged line - restoring your home as it was before we started working.`}
        titleTwo={`Main Replacement`}
        descriptionTwo={`If your sewer line is irreperably damaaged, our team is able to replace your sewer line. A sewer line replacement is a major fix, but we get the job done quickly and thoroughly.`}
        titleThree={`Sewer Line Installation`}
        descriptionThree={`We also take care of new sewer line installations. With over 30 years of experience, we can have your new line installed fast so you can enjoy all the comforts of your home.`}
      />

      <Testimonials />
      <ServiceAreas
        description={`sewer lines`}
        subDescription={`sewer line repair, replacement, and installation`}
      />
      <CTA title={`Get a Free Quote to Fix Your Sewer Line Now`} />
    </Layout>
  )
}

export default SewerLineRepair
